// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`SearchBar.vue render when created  1`] = `
<div
  class="user-fluid-layout__header-search-bar"
  id="userFluidLayoutHeaderSearchBar"
>
  <div
    class="input-group"
    role="group"
  >
    <!---->
    <div
      class="input-group-prepend"
    >
      <div
        class="input-group-text"
      >
        <img
          alt="search"
          height="18"
          src=""
          width="18"
        />
      </div>
    </div>
     
    <input
      class="user-fluid-layout__search--input form-control"
      placeholder="Search"
      type="text"
    />
     
    <b-input-group-append
      is-text=""
    >
      <!---->
    </b-input-group-append>
    <!---->
  </div>
   
  <!---->
</div>
`;
